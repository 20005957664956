import React from "react"
import BackgroundImage from "gatsby-background-image"
import { ClassNames  } from "@emotion/core"
import { mq } from "../layouts/theme"

export const Banner = ({ img, bgPosition }) => {
  return (
    <section>
      <ClassNames>
        {({ css }) => (
          <BackgroundImage
            Tag="div"
            className={css`
              background-position: ${bgPosition ? bgPosition: "center"} ;
              width: 100%;
              height: 200px;
              background-size: cover;
              ${mq.mobile} {
                height: 250px;
              }
              ${mq.tablet} {
                height: 300px;
              }
            `}
            fluid={img.childImageSharp.fluid}
          />
        )}
      </ClassNames>
    </section>
  )
}
