import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Banner } from "../components/banner"
import SEO from "../components/seo"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import { mq } from "../layouts/theme"
import { useTheme } from "emotion-theming"

const P = styled.p`
  letter-spacing: 1px;
  margin-bottom: 16px;
  font-size: 12px;
  font-family: ProximaNova-Regular;
`

const Terms = () => {
  const {
    colors: {
      bg: { lightGrey },
    },
  } = useTheme()
  const { banner, ogImg } = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "est-1992.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ogImg: file(relativePath: { eq: "est-1992.png" }) {
        childImageSharp {
          fixed {
            src
          }
        }
      }
    }
  `)
  return (
    <>
      <SEO
        title="Terms of Service"
        description="The content of this website, including but not limited to articles,
            prose, designs, pictures, logos or any written and visual materials,
            is the property of First Shelter Ventures & Realty Corporation and
            is protected by copyright, trademark and other intellectual property
            laws."
        img={ogImg.childImageSharp.fixed.src}
      />
      <Banner img={banner} bgPosition={"bottom left"} />
      <section
        css={css`
          padding: 50px 20px;
          background: ${lightGrey};
          ${mq.mobile} {
            padding: 50px 40px;
          }
          ${mq.tablet} {
            padding: 50px;
          }
          flex: 1;
        `}
      >
        <div
          css={css`
            max-width: 1400px;
            margin: 0 auto;
          `}
        >
          <h2
            css={css`
              font-family: ProximaNova-Bold;
              letter-spacing: 3px;
              text-align: center;
            `}
          >
            TERMS OF USE
          </h2>
          <br />
          <P>
            The content of this website, including but not limited to articles,
            prose, designs, pictures, logos or any written and visual materials,
            is the property of First Shelter Ventures & Realty Corporation and
            is protected by copyright, trademark and other intellectual property
            laws. Any information contained herein may not be reproduced,
            transmitted, distributed, displayed, sold, published, broadcasted,
            circulated or used in any manner without the prior written consent
            of First Shelter Ventures & Realty Corporation. First Shelter
            Ventures & Realty Corporation at its sole discretion, may choose to
            change the terms, conditions, and operation of this website at any
            time.
          </P>
          <P>
            The materials, particulars, details, and visuals shown herein are
            for information purposes only and are not to be relied upon as
            statement of fact. While information contained herein is based on
            present plans which have been prepared with utmost care and is given
            in good faith, these may or may not reflect the most current
            developments. The contents herein are subject to change without
            prior notice. Accordingly, readers and users of this website are
            invited to verify their factual and subsequent changes before acting
            upon any information contained herein. First Shelter Ventures &
            Realty Corporation makes no warranty, express of implied, or assumes
            any liability or responsibility for any information presented
            herein. The contents herein do not constitute part of an offer or
            contract.
          </P>
        </div>
      </section>
    </>
  )
}

export default Terms
